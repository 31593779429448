<template>
  <div style="padding: 1rem">
    <div
      style="
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
      "
    >
      <img
        style="width: 10px; height: auto"
        src="../assets/common-01.png"
        @click="goBack"
      /><SetLang />
    </div>

    <p style="margin: 2rem 0 1rem 0; font-size: 1.5rem; font-weight: bold">
      {{ $t("login.item_01") }}
    </p>

    <div style="padding: 2rem 0 0 0">
      <p style="margin: 0 0 0.5rem 0; font-size: 1rem; font-weight: bold">
        {{ $t("common.item_07") }}
      </p>
      <p style="margin: 0 1rem 0.5rem 1rem; color: red" v-if="emailMsg">
        {{ emailMsg }}
      </p>
      <div>
        <input
          style="
            padding: 0.8rem 80px 0.8rem 1rem;
            width: 100%;
            color: #898989;
            background-color: #f6f7fa;
            border-radius: 25px;
            outline: none;
            border: none;
          "
          :placeholder="$t('common.item_08')"
          v-model="emailAcc"
          @focus="
            () => {
              emailMsg = '';
            }
          "
        />
      </div>
    </div>

    <div style="padding: 2rem 0 0 0">
      <p style="margin: 0 0 0.5rem 0; font-size: 1rem; font-weight: bold">
        {{ $t("common.item_11") }}
      </p>
      <p style="margin: 0 1rem 0.5rem 1rem; color: red" v-if="pwdMsg">
        {{ pwdMsg }}
      </p>
      <div style="position: relative">
        <input
          style="
            padding: 0.8rem 80px 0.8rem 1rem;
            width: 100%;
            color: #898989;
            background-color: #f6f7fa;
            border-radius: 25px;
            outline: none;
            border: none;
          "
          :type="pwdShow ? 'text' : 'password'"
          :placeholder="$t('common.item_12')"
          v-model="pwd"
          @focus="
            () => {
              pwdMsg = '';
            }
          "
        />
        <img
          style="
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translate(0, -50%);
            width: 30px;
            height: auto;
          "
          :src="pwdImg(pwdShow)"
          @click="
            () => {
              pwdShow = pwdShow ? false : true;
            }
          "
        />
      </div>
    </div>

    <div style="padding: 2rem 0 0 0" v-if="checkCode">
      <p style="margin: 0 0 0.5rem 0; font-size: 1rem; font-weight: bold">
        {{ $t("common.item_34") }}
      </p>
      <p style="margin: 0 1rem 0.5rem 1rem; color: red" v-if="codeMsg">
        {{ codeMsg }}
      </p>
      <div
        style="
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;
        "
      >
        <input
          style="
            padding: 0.8rem 1rem;
            width: 65%;
            color: #898989;
            background-color: #f6f7fa;
            border-radius: 25px;
            outline: none;
            border: none;
          "
          :placeholder="$t('common.item_35')"
          v-model="code"
          @focus="
            () => {
              codeMsg = '';
            }
          "
        />
        <VCode v-model:value="validCode"></VCode>
      </div>
    </div>

    <q-btn
      class="full-width"
      style="
        margin: 2rem 0 0 0;
        background-color: #387bf8;
        padding: 0.7rem 0;
        font-size: 1rem;
        color: #fff;
      "
      rounded
      no-caps
      :label="$t('login.item_02')"
      @click="submit"
    />

    <p
      style="
        margin: 1rem 0 0 0;
        text-align: center;
        color: #387bf8;
        text-decoration: underline;
      "
    >
      <span @click="goRegister">{{ $t("login.item_03") }}</span>
      <span style="padding: 0 0.5rem">|</span>
      <span @click="goFind">{{ $t("login.item_04") }}</span>
    </p>
  </div>
</template>

<script>
import SetLang from "@/components/lang.vue";
import VCode from "@/components/vcode.vue";
export default {
  components: {
    SetLang,
    VCode,
  },
  data() {
    return {
      httpProcess: false,

      emailMsg: "",
      emailAcc: "",
      pwdShow: false,
      pwdMsg: "",
      pwd: "",

      checkCode: false,
      validCode: "",
      codeMsg: "",
      code: "",
    };
  },
  created() {
    this.showCode();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goRegister() {
      this.$router.push({ path: "/register" });
    },
    goFind() {
      this.$router.push({ path: "/find" });
    },
    clearMsg() {
      this.emailMsg = "";
      this.pwdMsg = "";
      this.codeMsg = "";
    },
    pwdImg(v) {
      if (v) {
        return require("../assets/common-02.png");
      } else {
        return require("../assets/common-03.png");
      }
    },
    showCode() {
      var code = parseInt(window.sessionStorage["code"]);
      if (code && code >= 3) {
        this.checkCode = true;
      }
    },
    submit() {
      var self = this;
      self.$buryPoint("user_login", {});
      if (!self.emailAcc) {
        self.emailMsg = self.$t("common.item_09");
        return;
      }
      if (
        !/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/.test(
          self.emailAcc
        )
      ) {
        self.emailMsg = self.$t("common.item_10");
        return;
      }
      if (!self.pwd) {
        self.pwdMsg = self.$t("common.item_13");
        return;
      }
      if (self.pwd.length < 6) {
        self.pwdMsg = self.$t("common.item_14");
        return;
      }
      if (self.checkCode && !self.code) {
        self.codeMsg = self.$t("common.item_36");
        return;
      }
      if (self.checkCode && self.code != self.validCode) {
        self.codeMsg = self.$t("common.item_37");
        return;
      }

      if (self.httpProcess) return;
      self.httpProcess = true;
      self
        .$axios({
          method: "post",
          url: "/api/login/check",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: {
            email_acc: self.emailAcc,
            pwd: self.pwd,
          },
        })
        .then(() => {
          window.sessionStorage["code"] = 0;
          self.httpProcess = false;
          self.$buryPoint("user_login_suc", {});
          self.$router.push({ path: "/" });
        })
        .catch(function (error) {
          var code = parseInt(window.sessionStorage["code"]);
          if (code) {
            window.sessionStorage["code"] = code + 1;
          } else {
            window.sessionStorage["code"] = 1;
          }
          self.showCode();
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
  },
};
</script>
